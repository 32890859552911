import "./team.scss"
import 'aos/dist/aos.css';

import React from "react"
import EmployeeModal from "./employeeModal.js"
import headshotHover from "../../../static/images/about/team/ob-headshot-hover.svg"
import funPlaceholder from "../../../static/images/about/team/funPlaceholder.svg"
import noPicture from "../../../static/images/about/team/honeycombHeadshotPlaceholder.png"
import { getStaticContentByIdentifier } from "../../services/misc.service";

import AOS from 'aos';

function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}
const filesBaseUrl = process.env.filesBaseUrl;
export default class extends React.Component {
  constructor(props) {
    super(props);
    shuffleArray(props.orangeBeesTeamArray);
    this.state = {
      leaders: [],
      team: [],
      counter: 0,
      windowHeight: 1000,
      bioModal: {
        name: "",
        employeeRole: "",
        bioPic: "",
        bioInfo: ""
      },
      openModal: false
    }

    this.handleRowClass = this.handleRowClass.bind(this);
    this.handleBioClick = this.handleBioClick.bind(this);
    this.closeBioModal = this.closeBioModal.bind(this);
    this.redefineTeamArray = this.redefineTeamArray.bind(this);
    this.returnPicture = this.returnPicture.bind(this);
  }

  componentDidMount() {
    AOS.init();
    this.setState({
      leaders: this.props.leadershipArray,
      team: this.redefineTeamArray(window.innerWidth)
    });

    window.addEventListener('resize', () => {
      let e = window, a = 'inner';
      if (!('innerWidth' in window)) {
        a = 'client';
        e = document.documentElement || document.body;
      }

      const windowWidth = e[a + 'Width'];
      const windowHeight = e[a + 'Height'];

      this.setState({
        team: this.redefineTeamArray(windowWidth),
        windowHeight: windowHeight
      });
    });
  }

  redefineTeamArray(width) {
    let numEvenColumns, numOddColumns;
    let whichRow = 'even';
    let newTeamArray = [{
      rowIndex: 0,
      teamMembers: [],
      shift: false
    }];
    if (width > 1100) {
      numEvenColumns = 7;
      numOddColumns = 6;
    } else if (width > 800) {
      numEvenColumns = 5;
      numOddColumns = 4;
    } else if (width > 494) {
      numEvenColumns = 4;
      numOddColumns = 3;
    } else if (width > 335) {
      numEvenColumns = 3;
      numOddColumns = 2;
    } else {
      numEvenColumns = 2;
      numOddColumns = 1;
    }

    this.props.orangeBeesTeamArray.forEach((person, i) => {
      newTeamArray[newTeamArray.length - 1].teamMembers.push(person)
      if ((whichRow === 'even' && newTeamArray[newTeamArray.length - 1].teamMembers.length >= numEvenColumns) ||
        (whichRow === 'odd' && newTeamArray[newTeamArray.length - 1].teamMembers.length >= numOddColumns)) {
        newTeamArray.push({
          rowIndex: newTeamArray.length,
          teamMembers: [],
          shift: false
        });
        whichRow = whichRow === 'even' ? 'odd' : 'even';
      }
    })
    if (newTeamArray.length > 1) {
      let oddOrEvenLastRow = parseInt(newTeamArray[newTeamArray.length - 1].teamMembers.length % 2) === 0 ? 'even' : 'odd';
      let oddOrEvenSecondToLastRow = parseInt(newTeamArray[newTeamArray.length - 2].teamMembers.length % 2) === 0 ? 'even' : 'odd';

      if (oddOrEvenLastRow === oddOrEvenSecondToLastRow) {
        newTeamArray[newTeamArray.length - 1].shift = true;
      }
    }
    return newTeamArray;
  }

  handleBioClick(employeeForBioModal) {
    // this.returnPicture(employeeForBioModal) ? this.returnPicture(employeeForBioModal) : funPlaceholder,
    this.setState({
      bioModal: {
        name: employeeForBioModal.fullName,
        employeeRole: employeeForBioModal.jobTitle,
        bioPic: this.returnPicture(employeeForBioModal, "fun") ? this.returnPicture(employeeForBioModal, "fun") : funPlaceholder,
        bioInfo: employeeForBioModal && employeeForBioModal.employeeBio ? employeeForBioModal.employeeBio : "A long time ago in a galaxy far, far away... Bio coming soon."
      },
    });

    this.handleClick()

    const mainModalScroll = document.getElementById('modal-wrapper');
    mainModalScroll.scrollTo(0, 0);
    document.querySelector('.modal-bio-info').scrollTo(0, 0);

    document.addEventListener("keydown", ({ key }) => {
      if (key === 'Escape' && this.state.openModal) {
        this.closeBioModal();
      }
    });
    document.documentElement.style.overflow = 'hidden';
    document.body.scroll = "no";
  }

  handleClick() {
    if (!this.state.openModal) {
      setTimeout(() => {
        document.addEventListener("click", this.handleOutsideClick, false);
      }, 0)

    }
    this.setState({
      openModal: true
    });
  };

  handleOutsideClick = e => {
    let el = document.querySelector(".employee-modal");
    if (!el.contains(e.target) && this.state.openModal) {
      this.setState({
        openModal: false
      });
    };
    document.removeEventListener("click", this.handleOutsideClick, false);
    document.documentElement.style.overflow = 'scroll';
    document.body.scroll = "yes";
  };

  handleFocusClick(employee) {
    document.addEventListener("keyup", ({ key }) => {
      if (key === 'Enter' && !this.state.openModal) {
        this.handleBioClick(employee);
      }
    }, false)
  }

  closeBioModal() {
    this.setState(prevState => ({
      bioModal: {
        name: "",
        employeeRole: "",
        bioPic: "",
        bioInfo: ""
      },
      openModal: !prevState.openModal
    }));
    document.documentElement.style.overflow = 'scroll';
    document.body.scroll = "yes";
  }

  createRandomDelay(x) {
    let random = Math.random() * (500 + 50);
    let randomMultiple = random - (random % 50);
    return randomMultiple;
  }

  returnPicture(employee, type) {
    if (employee && employee.primaryHeadshot && employee.primaryHeadshot.publicUrl && type === 'primary') {
      return `${filesBaseUrl}${employee.primaryHeadshot.publicUrl}`;
    } else if (employee && employee.secondaryFunHeadshot && employee.secondaryFunHeadshot.publicUrl && type === 'fun') {
      return `${filesBaseUrl}${employee.secondaryFunHeadshot.publicUrl}`;
    } else {
      return false
    }
  }

  handleRowClass(index, oddRowBool, applyShift) {
    let classNameToApply = ""
    if (oddRowBool && applyShift) {
      classNameToApply = "team-row odd-row shift"
    } else if (oddRowBool && !applyShift) {
      classNameToApply = "team-row odd-row"
    } else if (!oddRowBool && applyShift) {
      classNameToApply = "team-row even-row shift"
    } else if (!oddRowBool && !applyShift) {
      classNameToApply = "team-row even-row"
    }
    if (index === 0) {
      classNameToApply += " initial";
    }
    if (index !== 0 && index !== this.state.team.length - 1) {
      classNameToApply += " not-initial"
    }
    if (index === this.state.team.length - 1) {
      classNameToApply += " not-initial";
    }
    return classNameToApply;
  }

  render() {
    return (
      <section className="about-team padding-t-6 center">
        <div className="row">
          <div className="text-center">
            <div className="our-team-header text-center"><h2>{getStaticContentByIdentifier('about.team.title', this.props.staticContent)}</h2></div>
            <div className="text-center col-xs-12 col-sm-12"><p className="padding-l-3 padding-r-3">{getStaticContentByIdentifier('about.team.content', this.props.staticContent)}</p></div>
          </div>
        </div>
        <div className="leadership-header"><h3>{getStaticContentByIdentifier('about.team.subtitle1', this.props.staticContent)}</h3></div>
        <div className="row leader-row">
          {
            this.state.leaders.map((leader, i) => {
              return (
                <div key={leader.id} className="leader-column">
                  <div
                    role="button"
                    onFocus={() => { this.handleFocusClick(leader) }}
                    tabIndex={0}
                    onKeyDown={this.handleFocusClick(leader)}
                    onClick={() => { this.handleBioClick(leader) }}
                    className="leader-honeycomb"
                    style={{ backgroundImage: `url('${this.returnPicture(leader, 'primary') ? this.returnPicture(leader, 'primary') : noPicture}'` }}
                  >
                    {/* <div
                    tabIndex={0}
                    className="leader-honeycomb"
                    style={{ backgroundImage: `url('${leader.primaryHeadshot}'`}}
                  > */}
                    <div className="leader-hover-state">
                      <img
                        className="leader-hover-image"
                        src={headshotHover}
                        alt="leader-headshot-hover-graphic"
                      />
                      <div className="leader-hover-name">{leader.displayFirstName}</div>
                    </div>
                  </div>
                  <div className="leadership-info-div">
                    <div className="leadership-name text-uppercase">
                      <h3>{leader.name}</h3>
                    </div>
                    <div className="leadership-role">{leader.role}</div>
                  </div>
                </div>
              )
            })
          }
        </div>
        <div className="engineering-and-operations-header"><h3 className="text-center">{getStaticContentByIdentifier('about.team.subtitle2', this.props.staticContent)}</h3></div>
        <div className="engineering-team-honey-comb">
          {
            this.state.team.map((ob, i) => {
              return (
                // if shift, move over half width of image
                //  this.returnPicture(x) ? this.returnPicture(x) : noPicture 
                <div
                  key={ob.rowIndex + i}
                  className={this.handleRowClass(i, i%2, ob.shift)}
                >
                  {ob.teamMembers.map((x, j) => {
                    return (
                      // <div
                      //   data-aos="zoom-in"
                      //   data-aos-once="true"
                      //   data-aos-delay={this.createRandomDelay(ob.rowIndex)}
                      //   key={x.id + j}
                      //   onFocus={() => { this.handleFocusClick(x) }}
                      //   tabIndex={0}
                      //   onClick={() => { this.handleBioClick(x) }}
                      //   className="team-member-comb"
                      //   style={{ backgroundImage: `url('${this.returnPicture(x, 'primary') ? this.returnPicture(x, 'primary') : noPicture }'`}}
                      // >
                      <div
                        role="button"
                        data-aos="zoom-in"
                        data-aos-once="true"
                        data-aos-delay={this.createRandomDelay(ob.rowIndex)}
                        key={x.id + j}
                        onFocus={() => { this.handleFocusClick(x) }}
                        onKeyDown={this.handleFocusClick(x)}
                        tabIndex={0}
                        onClick={() => { this.handleBioClick(x) }}
                        className="team-member-comb"
                        style={{ backgroundImage: `url('${this.returnPicture(x, 'primary') ? this.returnPicture(x, 'primary') : noPicture}'` }}
                      >
                        <div className="employee-hover-state">
                          <img
                            className="headshot-hover-image"
                            src={headshotHover}
                            alt="headshot-hover-graphic"
                          />
                          <div className="employee-hover-name">{x.displayFirstName}</div>
                        </div>
                      </div>
                    )
                  })
                  }
                </div>
              )
            })
          }
        </div>
        <div className="employee-bio-modal">
          <EmployeeModal
            show={this.state.openModal}
            handleClose={this.closeBioModal}
            name={this.state.bioModal.name}
            role={this.state.bioModal.employeeRole}
            photo={this.state.bioModal.bioPic}
            bio={this.state.bioModal.bioInfo}
          ></EmployeeModal>
        </div>
      </section>
    )
  }
}