import './experience.scss';

import React from 'react';
import borderBottomHoneyComb from '../../../static/images/about/experience/dark-honeycomb-border-bottom.png';
import borderTopHoneyComb from '../../../static/images/about/experience/dark-honeycomb-border-top.png';
import engineeringIcon from '../../../static/images/about/experience/ITandEngin_OB.svg'
import manufacturingIcon from '../../../static/images/about/experience/manufacturing.svg'
import govMilitaryIcon from '../../../static/images/about/experience/government_OB.svg'
import bankingFinIcon from '../../../static/images/about/experience/bankingfina_OB.svg'
import healthIcon from '../../../static/images/about/experience/healthcare_OB.svg'

export default (props) => {
  const experienceArray = [
   {
    title: 'IT & Engineering',
    experienceAmount: '83',
    experienceLabel: 'YEARS',
    top: true,
    experienceIcon: engineeringIcon,
    iconDescription: 'Computer and Tools Icon',
    order: 1
   },
   {
    title: 'Manufacturing',
    experienceAmount: '82.5',
    experienceLabel: 'YEARS',
    top: false,
    experienceIcon: manufacturingIcon,
    iconDescription: 'Production Factory Icon',
    order: 2
   },
   {
    title: 'Government & Military',
    experienceAmount: '60',
    experienceLabel: 'YEARS',
    top: true,
    experienceIcon: govMilitaryIcon,
    iconDescription: 'Government Building Icon',
    order: 3
   },
   {
    title: 'Banking & Finance',
    experienceAmount: '15.5',
    experienceLabel: 'YEARS',
    top: false,
    experienceIcon: bankingFinIcon,
    iconDescription: 'Money Icon',
    order: 4
   },
   {
    title: 'Healthcare & Insurance',
    experienceAmount: '14',
    experienceLabel: 'YEARS',
    top: true,
    experienceIcon: healthIcon,
    iconDescription: 'Medical Kit Icon',
    order: 5
   },
  ];

  function roundUpNumber(number) {
    return Math.round(number)
  }

  const honeyCombTopStyle = {
    backgroundImage: 'url(' + borderTopHoneyComb + ')'
  }

  const honeyCombBottomStyle = {
    backgroundImage: 'url(' + borderBottomHoneyComb + ')'
  }
  return (
    <section className="experience-container center">
      <div className="w-100">
        <h2 className="experience-header">Our Experience</h2>
        <div className="experience-subheader text-center col-xs-12 col-sm-12">
          <p className="padding-l-3 padding-r-3">We pride ourselves on the vast knowledge and experience of our team. Below you will find our technical leadership's accumulated years of experience in related professional fields.</p>
        </div>
        <div className="experience-honeycomb-row row margin-b-6">
          {
            experienceArray.map((x, i) => {
              return (
                <div key={i} className={x.top ? 'experience-honeycomb-top' : 'experience-honeycomb-bottom'}>
                  <div className="experience-inner-honeycomb">
                    <div className="experience-icon-container">
                      <img className="experience-honeycomb-icon" src={x.experienceIcon} alt={x.iconDescription} />
                    </div>
                    <div className="experience-honeycomb-title-container">
                      <span className="experience-honeycomb-title">{x.title}</span>
                    </div>
                    <div className="experience-honeycomb-amount-container">
                      <h1 className="experience-honeycomb-amount">{roundUpNumber(x.experienceAmount)}</h1>
                    </div>
                    <div className="experience-honeycomb-amount-label-container">
                      <span className="experience-honeycomb-amount-label">{x.experienceLabel}</span>
                    </div>
                  </div>
                </div>
              )
            })
          }
        </div>
      </div>
    </section>
  )
}