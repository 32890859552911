import "./history.scss"

import React from "react"
import obLogo from '../../../static/images/about/OB-logo.svg';
import { getStaticContentByIdentifier } from "../../services/misc.service";
import { getStaticImageByIdentifier } from "../../services/image.service";

export default (props) => {
  

  return (
    <section className="about-history margin-b-6 margin-t-6 center">
      <div className="rows-center mobile-reverse">
        <div className="about-history-logo col-xs-12 col-sm-5 margin-b-3 o1">
          <img src={getStaticImageByIdentifier('about.OBLogo', props.staticImages)} alt="Orange Bees" />
        </div>
        <div className="col-xs-12 col-sm-7 o2">
          <div className="text-center">
            <h1>{getStaticContentByIdentifier('about.hero.title', props.staticContent)}</h1>
            <p className="text-justify padding-l-3 padding-r-3">
            {getStaticContentByIdentifier('about.hero.content', props.staticContent)}
          </p>
          </div>
        </div>
      </div>
    </section>
  )
}