import "./values.scss"

import React from "react"
import HoneyCombCard from "./honeyCombCard.js"
import trust from "../../../static/images/about/valueCardIcons/trust_OB_light.svg"
import quality from "../../../static/images/about/valueCardIcons/031-browser-1_OB_light.svg"
import innovation from "../../../static/images/about/valueCardIcons/034-startup_OB_light.svg"
import learning from "../../../static/images/about/valueCardIcons/033-book-3_OB_light.svg"
import honeycombIcon from "../../../static/images/about/valueCardIcons/orange_honeycomb_white_border.svg"
import retention from '../../../static/images/about/valueCardIcons/retention.svg';
import suit from '../../../static/images/about/valueCardIcons/suit.svg';
import Wave from "../wave"

import { getStaticContentByIdentifier } from "../../services/misc.service";

const valueArray = [
  {
    valueIcon: trust,
    extraParagraphWidth: false,
    separatingIcon: honeycombIcon,
    valueTitleLine1: "ABOVE ALL, TRUST",
    valueTitleLine2: "MATTERS MOST",
    valueInfo: "The only way to build strong relationships with our clients and employees is through transparency and integrity."
  },
  {
    valueIcon: retention,
    extraParagraphWidth: false,
    separatingIcon: honeycombIcon,
    valueTitleLine1: "CLIENT",
    valueTitleLine2: "FIRST",
    valueInfo: "We are devoted to the success of our clients, adapting to their needs and always seeking to understand their perspective."
  },
  {
    valueIcon: suit,
    extraParagraphWidth: false,
    separatingIcon: honeycombIcon,
    valueTitleLine1: "RESPECT &",
    valueTitleLine2: "PROFESSIONALISM",
    valueInfo: "We always treat everyone with courtesy and respect, providing services with the highest level of professionalism."
  },
  {
    valueIcon: quality,
    extraParagraphWidth: false,
    separatingIcon: honeycombIcon,
    valueTitleLine1: "NEVER SACRIFICE",
    valueTitleLine2: "QUALITY",
    valueInfo: "No matter what challenges are presented, both our clients and employees demand the highest of quality in the work that we do."
  },
  {
    valueIcon: innovation,
    extraParagraphWidth: true,
    separatingIcon: honeycombIcon,
    valueTitleLine1: "INNOVATION OF",
    valueTitleLine2: "EVERYTHING",
    valueInfo: "Whether it means finding new technical solutions or implementing new internal processes, we believe the best way to bring value to our clients is through continual improvement and innovation."
  },
  {
    valueIcon: learning,
    extraParagraphWidth: false,
    separatingIcon: honeycombIcon,
    valueTitleLine1: "KEEP",
    valueTitleLine2: "LEARNING",
    valueInfo: "We love what we do, and so we continue to learn new technologies allowing us to deliver innovative and cost effective solutions to business challenges."
  }
] 

export default (props) => {
  return (
    <section className="grey-bg center">
      <Wave color="#f8f8f8" background="#ffffff" />
      <div className="w-100">
        <div className="column">
          <h2 className="value-header">{getStaticContentByIdentifier('about.values.title', props.staticContent)}</h2>
        </div>
      </div>
        <div className="col-xs-12 col-sm-12">
          <div className="row value-row">
            { valueArray.map((val,i) => {
              return (
                <HoneyCombCard
                  key={i}
                  valueIcon={val.valueIcon}
                  valueInfo={val.valueInfo}
                  valueTitleLine1={val.valueTitleLine1}
                  valueTitleLine2={val.valueTitleLine2}
                  separatingIcon={val.separatingIcon}
                  extraParagraphWidth={val.extraParagraphWidth}
                ></HoneyCombCard>
              )
            })}
          </div>
        </div>
    </section>
  )
}