import Cta from "../components/cta"
import Footer from "../components/footer"
import Header from "../components/header"
import Hero from "../components/about/hero"
import History from '../components/about/history'
import Layout from "../components/layout"
import React from "react"
import Seo from '../components/seo2';
import Team from "../components/about/team";
import Values from "../components/about/values";
import Experience from "../components/about/experience";
import { getStaticContentByIdentifier } from "../services/misc.service";
import { getSEOByIdentifier } from "../services/seo.service";

const AboutPage = props => {
  const staticContent = props.pageContext.staticContent;
  const staticImages = props.pageContext.staticImages;
  const orangeBeesTeamArray = props.pageContext.employees;
  const leadershipArray = props.pageContext.leaders;
  const valueArray = props.pageContext.values;
  const SEOInfo = props.pageContext.SEOInfo;

  return (
    <Layout>
      <Seo
        SEOInfo={getSEOByIdentifier('about', SEOInfo)}
      />
      <Header staticImages={staticImages}/>
      <main id="maincontent">
        <History staticContent={staticContent} staticImages={staticImages}/>
        <Values staticContent={staticContent} valueArray={valueArray}/>
        <Team staticContent={staticContent} orangeBeesTeamArray={orangeBeesTeamArray} leadershipArray={leadershipArray} />
        <Experience />
        <Cta title={getStaticContentByIdentifier('about.CTA.content', staticContent)} button={getStaticContentByIdentifier('about.CTA.button', staticContent)} />
      </main>
      <Footer />
    </Layout>
  )
}

export default AboutPage;