import './honeyCombCard.scss';
import honeyCombBackgroundDark from '../../../static/images/about/OB_Honeycomb_Pattern_Dark_Transparent_Plain_repeat_opacity20.png'
import React from 'react';

export default (props) => {
  return (
    <div className="column">
      <div
        className="honeycomb-card"
        style={{ backgroundImage: `url('${honeyCombBackgroundDark}'), radial-gradient(closest-side at 50% 50%, #383838 0%, #191919 100%)`}}
      >
        <img
          className="honeycomb-card-icon"
          src={props.valueIcon}
          alt="Value Icon"
        />
        <div className="honeycomb-card-title">
          {
            (
              <div className="honeycomb-card-title-div">
                <h3 className="honeycomb-card-title-h3">{props.valueTitleLine1}</h3>
                <h3 className="honeycomb-card-title-h3">{props.valueTitleLine2}</h3>
              </div>
            )
          }
        </div>
        <div className="honeycomb-card-divider"></div>
        <div className="honeycomb-card-info-container">
          <div className="row">
            <div className="honeycomb-card-info">
              {/* <p className={props.extraParagraphWidth ? 'value-info-extend-width' : 'value-info'}>
                {props.valueInfo}
              </p> */}
              <p className="honeycomb-card-info-p">{props.valueInfo}</p>
            </div>
          </div>
        </div>
        <div className="honeycomb-card-honey-comb"><img src={props.separatingIcon} alt="Orange Honeycomb Icon" /></div>
      </div>
    </div>
  )
}