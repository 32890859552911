import "./employeeModal.scss"

import React from 'react';

export default (props) => {
  const showHideModal = props.show ? "modal-container display-block" : "modal-container display-none"
  return (
    <div className={showHideModal}>
      <section id="employee-modal" className="employee-modal">
        <div id="modal-wrapper" className="modal-wrapper">
          <div className="modal-row">
            <div className="modal-photo" style={{ backgroundImage: `url('${props.photo}'`}}></div>
            <div className="modal-bio-section">
              <div className="modal-bio-name text-left"><h3 className="modal-bio-name-h3 text-uppercase">{props.name}</h3></div>
              <div className="modal-bio-role text-left">{props.role}</div>
              <div className={ (props.role && props.role.length > 28) || (props.name && props.name.length > 17) ? "modal-bio-info text-left big-role-class" : "modal-bio-info text-left"}>{props.bio}</div>
            </div>
          </div>
        </div>
        <button className="modal-close-button btn-secondary" onClick={ props.handleClose }>Close</button>
      </section>
    </div>
  )
}